import * as React from 'react';
import LocationDetails from '../../pageSections/locations/locationDetails';
import LocationDefault from '../../pageSections/locations/locationDefault';
import { Router } from '@reach/router';

const LocationPageTemplateRoute = ({ showMapLeftPanel }) => {
  return (
    <Router>
      <LocationDefault path="/locations" showMapLeftPanel={showMapLeftPanel} />
      <LocationDetails path="/locations/*" />
    </Router>
  );
};

export default LocationPageTemplateRoute;

import * as React from 'react';
import { useState } from 'react';
import DefaultHeaderMenu from '../../components/defaultHeaderMenu/defaultHeaderMenu';
import BootstrapNavbar from '../../components/navigationBar/mainMenu/navigationBar';
import Footer from '../../components/footer/footer';
import TopBanner from '../../common/topBanner/topBanner';
import BreadcrumbsBlock from '../../common/breadcrumbsBlock/breadcrumbsBlock';
import LocationPageTemplateRoute from './locationPageTemplateRoute';
import GlobalStyles from '../../styles/globalStyles';
import SubFooter from '../../components/subFooter/subFooter';

const BreadcrumbsBlockData = {
  backgroundColor: '#ffc423',
  borderTopColor: '#e4ae1b',
  title: 'Locations',
};

const LocationPageTemplate = ({ location }) => {
  const path = location?.pathname?.split('/')[1];
  const [showMapLeftPanel, setShowMapLeftPanel] = useState(true);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  return !loaded ? (
    <div style={{ background: '#fff' }}>
      <GlobalStyles />
      <DefaultHeaderMenu />
      <BootstrapNavbar
        activePath={location.pathname}
        activePathImageTop="#037ec4"
        activeLink={path}
      />
      <TopBanner path={location.pathname} />
      <BreadcrumbsBlock
        {...BreadcrumbsBlockData}
        path={location.pathname}
        isLocation={true}
        onClick={() => {
          setShowMapLeftPanel(!showMapLeftPanel);
        }}
      />
      <LocationPageTemplateRoute showMapLeftPanel={showMapLeftPanel} />
      <Footer showProducts={true} />
      <SubFooter />
    </div>
  ) : (
    <div style={{ background: '#fff' }}>
      <GlobalStyles />
      <DefaultHeaderMenu />
      <BootstrapNavbar
        activePath={location.pathname}
        activePathImageTop="#037ec4"
        activeLink={path}
      />
      <TopBanner path={location.pathname} />
      <BreadcrumbsBlock
        {...BreadcrumbsBlockData}
        path={location.pathname}
        isLocation={true}
        onClick={() => {
          setShowMapLeftPanel(!showMapLeftPanel);
        }}
      />
      <LocationPageTemplateRoute showMapLeftPanel={showMapLeftPanel} />
      <Footer showProducts={true} />
      <SubFooter />
    </div>
  );
};

export default LocationPageTemplate;

import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import * as Styled from '../../templates/locationTemplate/locationPageTemplateStyle';
import IconPinBranch from '../../images/icon-pin-branch.png';
import IconPinCardlock from '../../images/icon-pin-cardlock.png';
import IconPinPayment from '../../images/icon-pin-payment.png';
import IconListing from '../../images/icon-listing.gif';
import GoogleMapReact from 'google-map-react';
import { Row, Col, Navbar, Nav } from 'react-bootstrap';
import LocationPinMarker from '../../common/locationMarker/locationMarker';
import { LocationTypes } from '../../utils/utils';
import Hamburger from '../../images/icon-hamburger.png';
import { Helmet } from 'react-helmet';
import { addTrailingSlash, isExternalUrlhref } from '../../utils';

const LocationDefault = ({ location, showMapLeftPanel }) => {
  const [showLocalBranchesMarkers, setShowLocalBranchesMarkers] =
    useState(true);
  const [showCardlockMarkers, setShowCardlockMarkers] = useState(true);
  const [showPaymentMarkers, setShowPaymentMarkers] = useState(true);
  const input = useRef(null);

  typeof window !== 'undefined' &&
    window.addEventListener('pageshow', (event) => {
      const historyTraversal =
        event.persisted ||
        (typeof window.performance !== 'undefined' &&
          window.performance.navigation.type === 2);

      if (historyTraversal) {
        window.location.reload();
      }
    });
  const types = {
    Branch: 'Branch',
    Cardlock: 'Marine Cardlock',
    Payment: 'Payment Centre',
  };
  const locationDataQuery = useStaticQuery(graphql`
    query locationDataMyQueryDefault {
      allContentfulLocation(
        filter: {
          node_locale: { eq: "en-US" }
          brand: { brand: { eq: "Island" } }
        }
      ) {
        edges {
          node {
            address1
            address2
            node_locale
            branchHours
            branchManager
            cardlockHours
            email
            hasDef
            type {
              type
            }
            hasDiesel
            hasGasoline
            hasHeatingOil
            hasHeatingEquipment
            hasLubricants
            hasPropane
            latitude
            location
            longitude
            phone
            tollfree
            pipelineCardAccepted
            postalCode
            serviceArea {
              serviceArea
            }
            city
            province
            id
            spaceId
            useLatLongOnly
            urlSlug
            fax
          }
        }
      }
    }
  `);
  let phones;
  const [locationsData, setLocationsData] = useState(
    locationDataQuery?.allContentfulLocation?.edges
  );

  const [lat, setLat] = useState(parseFloat(locationsData[0]?.node?.latitude));
  const [lng, setLng] = useState(parseFloat(locationsData[0]?.node?.longitude));
  const [currentMarker, setCurrentMarker] = useState('');
  const [zoom, setZoom] = useState(4);

  const getMarkerIcon = (types) => {
    let markerIcon = null;
    if (types.length === 1) {
      types.forEach((locationType) => {
        if (locationType.type === LocationTypes.Branch) {
          if (showLocalBranchesMarkers && markerIcon == null) {
            markerIcon = IconPinBranch;
          }
        }
        if (locationType.type === LocationTypes.Cardlock) {
          if (showCardlockMarkers && markerIcon == null) {
            markerIcon = IconPinCardlock;
          }
        }
        if (locationType.type === LocationTypes.Payment) {
          if (showPaymentMarkers) {
            markerIcon = IconPinPayment;
          }
        }
      });
    }
    return markerIcon;
  };

  useEffect(() => {
    setZoom(8);
    if (location?.search !== '') {
      setShowLocalBranchesMarkers(
        location.search.includes(LocationTypes.Branch.toLowerCase())
      );
      setShowCardlockMarkers(
        location.search.includes(LocationTypes.Cardlock.toLowerCase())
      );
      setShowPaymentMarkers(location.search.includes('ldc'));
    }
  }, []);

  useEffect(() => {
    let result = locationDataQuery?.allContentfulLocation?.edges;

    if (!showLocalBranchesMarkers) {
      result = result?.filter(
        (data) =>
          data.node.type[0].type !== LocationTypes.Branch ||
          data.node.type.length > 1
      );
    }

    if (!showCardlockMarkers) {
      result = result?.filter(
        (data) =>
          data.node.type[0].type !== LocationTypes.Cardlock ||
          data.node.type.length > 1
      );
    }

    if (!showPaymentMarkers) {
      result = result?.filter(
        (data) => data.node.type[0].type !== LocationTypes.Payment
      );
    }

    if (!showLocalBranchesMarkers && !showCardlockMarkers) {
      result = result?.filter((data) => data.node.type.length === 1);
    }

    setLocationsData(result);
    setLat(parseFloat(result[0]?.node?.latitude));
    setLng(parseFloat(result[0]?.node?.longitude));
  }, [showLocalBranchesMarkers, showCardlockMarkers, showPaymentMarkers]);

  const redirect = (urls) => {
    window.location.href = `/locations/${urls}`;
  };

  const handleApiLoaded = (map, maps) => {
    const styles = [
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{ color: '#98d0ff' }],
      },
    ];

    // eslint-disable-next-line no-undef
    const styledMap = new google.maps.StyledMapType(styles, {
      name: 'Styled Map',
    });
    map.mapTypes.set('map_style', styledMap);
    map.setMapTypeId('map_style');
  };
  if (typeof window === 'undefined') {
    return null;
  } else {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Home Heating & Commercial Fuel Locations Near You | Island Petroleum
          </title>
          <meta name="description" content></meta>
        </Helmet>
        <Styled.LocationContainer>
          <Row className="d-none d-md-flex d-lg-none">
            <Col style={{ position: 'relative', margin: 'auto' }} md={6}></Col>
            <Col md={6}>
              <Navbar expand="lg">
                <Styled.NavbarToggle aria-controls="responsive-navbar-nav">
                  <Styled.ResponsiveMenuSection className="g-0">
                    <Styled.ResponsiveCol md={12}>
                      <Styled.ArrowImage src={Hamburger} alt="Hamburger icon" />
                      <Styled.HeaderText>FILTER LOCATIONS</Styled.HeaderText>
                    </Styled.ResponsiveCol>
                  </Styled.ResponsiveMenuSection>
                </Styled.NavbarToggle>
                <Styled.NavbarCollapse id="responsive-navbar-nav">
                  <Nav.Link as="div" href="#" className="p-0">
                    <Styled.TabContainer>
                      <Styled.MapH2>
                        What type of location are you looking for?
                      </Styled.MapH2>
                      <div>
                        <Styled.CheckboxPin>
                          <Styled.CheckBoxInput
                            type="checkbox"
                            id={LocationTypes.Branch}
                            value={LocationTypes.Branch}
                            onChange={() =>
                              setShowLocalBranchesMarkers(
                                !showLocalBranchesMarkers
                              )
                            }
                            checked={showLocalBranchesMarkers}
                          />
                          <span>
                            <Styled.DropPinIcon
                              src={IconPinBranch}
                              alt="Local Branch"
                            />
                            Local Branch
                          </span>
                        </Styled.CheckboxPin>
                        <Styled.CheckboxPin>
                          <Styled.CheckBoxInput
                            type="checkbox"
                            id={LocationTypes.Cardlock}
                            value={LocationTypes.Cardlock}
                            checked={showCardlockMarkers}
                            onChange={() =>
                              setShowCardlockMarkers(!showCardlockMarkers)
                            }
                          />
                          <span>
                            <Styled.DropPinIcon
                              src={IconPinCardlock}
                              alt="Local Branch"
                            />
                            Marine Cardlock
                          </span>
                        </Styled.CheckboxPin>
                      </div>

                      <Styled.CheckboxPin>
                        <Styled.CheckBoxInput
                          type="checkbox"
                          id={LocationTypes.Payment}
                          value={LocationTypes.Payment}
                          checked={showPaymentMarkers}
                          onChange={() =>
                            setShowPaymentMarkers(!showPaymentMarkers)
                          }
                        />
                        <span>
                          <Styled.DropPinIcon
                            src={IconPinPayment}
                            alt=" Payment Centre"
                          />
                          Payment Centre
                        </span>
                      </Styled.CheckboxPin>
                      <Styled.ListingContainer>
                        <Styled.ListingTitle href="#tabs" className="scroll">
                          <Styled.ListingImage src={IconListing} />
                          View Location Listing
                        </Styled.ListingTitle>
                      </Styled.ListingContainer>
                    </Styled.TabContainer>
                  </Nav.Link>
                </Styled.NavbarCollapse>
              </Navbar>
            </Col>
          </Row>
          <Row className="g-0 d-none d-md-block">
            <Styled.LocationDataCol>
              <Styled.GoogleMapContainer>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.GATSBY_GOOGLE_MAP_APIKEY,
                    libraries: ['places', 'geometry'],
                  }}
                  center={{ lat, lng }}
                  zoom={zoom}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map, maps }) =>
                    handleApiLoaded(map, maps)
                  }
                >
                  {locationsData.map((location, index) => {
                    if (location.node.address1) {
                      return (
                        <LocationPinMarker
                          key={`${location.node.id}-${index}`}
                          id={location.node.id}
                          currentMarker={currentMarker}
                          lat={location.node.latitude}
                          lng={location.node.longitude}
                          {...location.node}
                          src={getMarkerIcon(location.node.type)}
                          onMarkerClick={(data) => {
                            setCurrentMarker(data.id);
                            setLat(parseFloat(data.lat));
                            setLng(parseFloat(data.lng));
                          }}
                        ></LocationPinMarker>
                      );
                    }
                  })}
                </GoogleMapReact>

                <Styled.MapLeftBarContainer>
                  {showMapLeftPanel && (
                    <Styled.MapLeftBar>
                      <Styled.MapH2>
                        What type of location are you looking for?
                      </Styled.MapH2>
                      <div>
                        <Styled.CheckboxPin>
                          <Styled.CheckBoxInput
                            type="checkbox"
                            id={LocationTypes.Branch}
                            value={LocationTypes.Branch}
                            onChange={() =>
                              setShowLocalBranchesMarkers(
                                !showLocalBranchesMarkers
                              )
                            }
                            checked={showLocalBranchesMarkers}
                          />
                          <span>
                            <Styled.DropPinIcon
                              src={IconPinBranch}
                              alt="Local Branch"
                            />
                            Local Branch
                          </span>
                        </Styled.CheckboxPin>
                        <Styled.CheckboxPin>
                          <Styled.CheckBoxInput
                            type="checkbox"
                            id={LocationTypes.Cardlock}
                            value={LocationTypes.Cardlock}
                            checked={showCardlockMarkers}
                            onChange={() =>
                              setShowCardlockMarkers(!showCardlockMarkers)
                            }
                          />
                          <span>
                            <Styled.DropPinIcon
                              src={IconPinCardlock}
                              alt="Local Branch"
                            />
                            Marine Cardlock
                          </span>
                        </Styled.CheckboxPin>
                      </div>

                      <Styled.CheckboxPin>
                        <Styled.CheckBoxInput
                          type="checkbox"
                          id={LocationTypes.Payment}
                          value={LocationTypes.Payment}
                          checked={showPaymentMarkers}
                          onChange={() =>
                            setShowPaymentMarkers(!showPaymentMarkers)
                          }
                        />
                        <span>
                          <Styled.DropPinIcon
                            src={IconPinPayment}
                            alt=" Payment Centre"
                          />
                          Payment Centre
                        </span>
                      </Styled.CheckboxPin>
                      <Styled.ListingContainer>
                        <Styled.ListingTitle href="#tabs" className="scroll">
                          <Styled.ListingImage src={IconListing} />
                          View Location Listing
                        </Styled.ListingTitle>
                      </Styled.ListingContainer>
                    </Styled.MapLeftBar>
                  )}
                </Styled.MapLeftBarContainer>
              </Styled.GoogleMapContainer>
            </Styled.LocationDataCol>
          </Row>
          <Row style={{ paddingTop: '20px' }} className="g-0 " id="tabs">
            <Styled.TableSubContainer xs={12} sm={12} md={12} lg>
              <Styled.LocationTable striped borderless>
                <Styled.THead>
                  <tr>
                    <th style={{ width: '25%' }}>ADDRESS</th>
                    <th style={{ width: '15%' }}>PHONE</th>
                    <th style={{ width: '15%' }}>EMAIL</th>
                    <th style={{ width: '12%' }}>TYPE</th>
                    <th style={{ width: '13%' }}>MANAGER</th>
                    <th style={{ width: '20%' }}>HOURS</th>
                  </tr>
                </Styled.THead>
                <Styled.TBody>
                  {locationsData
                    ?.sort((a, b) =>
                      a.node.location.localeCompare(b.node.location)
                    )
                    .map((item, index) => {
                      return (
                        <tr key={index}>
                          <Styled.Td>
                            <div className="d-flex flex-column ">
                              <Styled.CityText>
                                {item.node.location}
                              </Styled.CityText>
                              <Styled.LinkStyle
                                to={
                                  isExternalUrlhref(item.node.urlSlug)
                                    ? item.node.urlSlug
                                    : addTrailingSlash(
                                        process.env.GATSBY_DOMAIN_URL +
                                          `/locations/${item.node.urlSlug}`
                                      )
                                }
                              >
                                <Styled.AddressText>
                                  <div> {item.node.address1}</div>
                                  <div> {item.node.address2}</div>
                                  <div>
                                    {item.node.city}, {item.node.province}
                                    &nbsp;&nbsp;
                                    {item.node.postalCode}
                                  </div>
                                </Styled.AddressText>
                              </Styled.LinkStyle>
                            </div>
                          </Styled.Td>
                          <Styled.Td>
                            {item.node.phone &&
                              (phones = item.node.phone.split(' ')) && (
                                <>
                                  {phones[0] !== '!' && (
                                    <>
                                      <Styled.AddressParaText>
                                        Phone:
                                      </Styled.AddressParaText>
                                      <Styled.LinkStyle2
                                        className="popup-anchor"
                                        href={`tel:${phones[0]}`}
                                        tabindex="0"
                                      >
                                        {phones[0]}
                                      </Styled.LinkStyle2>
                                    </>
                                  )}
                                  {phones[1] && (
                                    <>
                                      <Styled.AddressParaText>
                                        Summerside:
                                      </Styled.AddressParaText>
                                      <Styled.LinkStyle2
                                        className="popup-anchor"
                                        href={`tel:${phones[1]}`}
                                        tabindex="0"
                                      >
                                        {phones[1]}
                                      </Styled.LinkStyle2>
                                    </>
                                  )}
                                </>
                              )}
                            {item.node.fax && (
                              <>
                                <Styled.AddressParaText>
                                  Fax:
                                </Styled.AddressParaText>
                                <Styled.LinkStyle2
                                  className="popup-anchor"
                                  href={`tel:${item.node.fax}`}
                                  tabindex="0"
                                >
                                  {item.node.fax}
                                </Styled.LinkStyle2>
                              </>
                            )}
                            {item.node.tollfree && (
                              <>
                                <Styled.AddressParaText>
                                  Toll Free:
                                </Styled.AddressParaText>
                                <Styled.LinkStyle2
                                  className="popup-anchor"
                                  href={`tel:${item.node.tollfree}`}
                                  tabindex="0"
                                >
                                  {item.node.tollfree}
                                </Styled.LinkStyle2>
                              </>
                            )}
                          </Styled.Td>
                          <Styled.Td>
                            <Styled.LinkStyle2
                              className="popup-anchor"
                              href={`mailto:${item.node.email}?subject=${item.node.city} Contact Request`}
                            >
                              {item.node.email}
                            </Styled.LinkStyle2>
                          </Styled.Td>
                          <Styled.Td>
                            {item.node.type.map((locationType, index) => {
                              return (
                                <div style={{ display: 'block' }} key={index}>
                                  {types[locationType.type]}
                                  {index !== item.node.type.length - 1
                                    ? ','
                                    : ''}
                                </div>
                              );
                            })}
                          </Styled.Td>
                          <Styled.Td>{item.node.branchManager}</Styled.Td>
                          <Styled.Td>
                            <div>
                              {item.node?.branchHours &&
                                item.node?.branchHours
                                  .split(' ')
                                  .map((item, index) => {
                                    return (
                                      <>
                                        {item}
                                        {index === 0 && <br />}
                                      </>
                                    );
                                  })}
                            </div>
                            <div>
                              {item.node?.cardlockHours &&
                                'Cardlock: ' + item.node?.cardlockHours}
                            </div>
                          </Styled.Td>
                        </tr>
                      );
                    })}
                </Styled.TBody>
              </Styled.LocationTable>
            </Styled.TableSubContainer>
          </Row>
        </Styled.LocationContainer>
      </>
    );
  }
};

export default LocationDefault;

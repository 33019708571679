import React from 'react';
import { isExternalUrlhref, addTrailingSlash } from '../../utils';
import * as Styled from './locationMarkerStyle';

const LocationMarker = (props) => {
  const [showPopup, setShowPopup] = React.useState(false);
  const handleClick = () => {
    setShowPopup(true);
    props.onMarkerClick(props);
  };
  let phones;
  const markerStyle = {
    position: 'absolute',
    height: '50px',
    transform: 'translate(-50%, -100%)',
  };

  return (
    <div>
      {showPopup && props.id === props.currentMarker && (
        <Styled.MainDiv>
          <Styled.InnerDiv>
            <Styled.DivStyle>
              <Styled.PopupCloseStyle
                onClick={() => {
                  setShowPopup(false);
                }}
              >
                x
              </Styled.PopupCloseStyle>
              <Styled.InfoWindowStyle>
                <Styled.HeaderH2>{props.location}</Styled.HeaderH2>
                <br />
                <Styled.InfoWindowLeft>
                  {props.address1}
                  <br />
                  {props.address2 && (
                    <>
                      {props.address2}
                      <br />
                    </>
                  )}

                  {props.city + ', ' + props.province + ', ' + props.postalCode}
                  <br />
                  <br />
                  <Styled.HeaderH3>Contact</Styled.HeaderH3>
                  {props.phone && (phones = props.phone.split(' ')) && (
                    <>
                      {phones[0] !== '!' && (
                        <>
                          Phone:
                          <br />
                          <Styled.PopupAnchor
                            className="popup-anchor"
                            href={`tel:${phones[0]}`}
                            tabindex="0"
                          >
                            {phones[0]}
                          </Styled.PopupAnchor>
                        </>
                      )}
                      {phones[1] && (
                        <>
                          <br />
                          Summerside:
                          <br />
                          <Styled.PopupAnchor
                            className="popup-anchor"
                            href={`tel:${phones[1]}`}
                            tabindex="0"
                          >
                            {phones[1]}
                          </Styled.PopupAnchor>
                        </>
                      )}
                    </>
                  )}
                  {props.fax && (
                    <>
                      <br />
                      Fax:
                      <br />
                      <Styled.PopupAnchor
                        className="popup-anchor"
                        href={`tel:${props.fax}`}
                        tabindex="0"
                      >
                        {props.fax}
                      </Styled.PopupAnchor>
                    </>
                  )}
                  <br />
                  {props.tollfree && (
                    <>
                      Toll Free:
                      <br />
                      <Styled.PopupAnchor
                        className="popup-anchor"
                        href={`tel:${props.tollfree}`}
                        tabindex="0"
                      >
                        {props.tollfree}
                      </Styled.PopupAnchor>
                    </>
                  )}
                  <br />
                  <Styled.PopupAnchor
                    href={`mailto:${props.email}?subject=La Ronge Contact Request`}
                  >
                    {props.email}
                  </Styled.PopupAnchor>
                </Styled.InfoWindowLeft>
                <Styled.InfoWindowRight>
                  <Styled.HeaderH3>Branch Manager</Styled.HeaderH3>
                  {props.branchManager}
                  <br />
                  <br />
                  <Styled.HeaderH3>Branch Hours</Styled.HeaderH3>
                  {props.branchHours &&
                    props.branchHours.split(' ').map((item, index) => {
                      return (
                        <>
                          {item}
                          {index === 0 && <br />}
                        </>
                      );
                    })}
                  {props?.cardlockHours && (
                    <>
                      <br />
                      Cardlock: {props?.cardlockHours}
                    </>
                  )}
                  <br />
                  <br />
                  <Styled.PopupAnchorLink
                    href={
                      isExternalUrlhref(props.urlSlug)
                        ? props.urlSlug
                        : addTrailingSlash(process.env.GATSBY_DOMAIN_URL +
                          `/locations/${props.urlSlug}`)
                    }
                  >
                    View Service Areas
                  </Styled.PopupAnchorLink>
                </Styled.InfoWindowRight>
              </Styled.InfoWindowStyle>
            </Styled.DivStyle>
          </Styled.InnerDiv>
        </Styled.MainDiv>
      )}
      <img style={markerStyle} src={props.src} onClick={handleClick} />
    </div>
  );
};

export default LocationMarker;
